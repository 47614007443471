<template>
  <DefaultField
      :helpText="helpText"
    :error="error"
  >
    <div class="flex items-center">
      <AppLabel>

      <input type="checkbox"
          ref="input"
          :value="value"
          class="rounded items-center"
           :checked="value"
           @input="$emit('check')"
           :class="[
             hasError
             ? 'text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 border-red-300'
             : 'text-gray-900 shadow-sm focus:ring-violet-500 focus:border-violet-500 border-gray-300'
          ]"
      >
        <slot></slot>
      </AppLabel>
    </div>
  </DefaultField>
</template>


<script>
import DefaultField from "@/components/DefaultField.vue";
import defaultField from "@/mixins/defaultField";
import AppLabel from "@/components/AppLabel.vue";

export default {
  mixins: [defaultField],
  emits: ['check'],
  components: {
    AppLabel,
      DefaultField,
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
  }
}
</script>