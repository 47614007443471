class PaymentJsErrorCodes {
    constructor() {
        this.attributes = {
            "integration_key": {
                "errors.configuration" : "Nevažeći ključ integracije",
                "errors.system" : "Došlo je do sistemske greške, pokušajte ponovo",
            },
            "number": {
                "errors.blank" : "Broj kartice je obavezan",
                "errors.invalid" : "Nevažeći broj kreditne kartice",
            },
            "cvv": {
                "errors.blank" : "CVV kod je obavezan",
                "errors.invalid" : "Nevažeći CVV kod",
            },
            "month": {
                "errors.blank" : "Mjesec isteka je obavezan",
                "errors.invalid" : "Nevažeći mjesec isteka",
            },
            "year": {
                "errors.blank" : "Godina isteka je obavezna",
                "errors.invalid" : "Nevažeća godina isteka",
                "errors.expired" : "Kartica je istekla",
            },
            "card_holder": {
                "errors.blank" : "Ime na kartici je obavezno",
            },
            "first_name": {
                "errors.blank" : "Ime je obavezno",
            },
            "last_name": {
                "errors.blank" : "Prezime je obavezno",
            },
        }
    }

    getMessage(attribute, key) {
        return this.attributes[attribute][key];
    }
}

export default new PaymentJsErrorCodes();
