<template>
  <ul v-if="products.length"
      class="space-y-4"
  >
    <li v-for="product in products"
        :key="product.name"
    >
      <div class="flex items-start">
        <div class="flex-shrink-0 w-10 h-10 mr-4">
          <img v-if="product.image" class="w-full h-full object-cover rounded"
               :src="url + product.image"
               :alt="product.name"
          >
          <svg v-else xmlns="http://www.w3.org/2000/svg" class="w-full h-full object-cover rounded" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
          </svg>
        </div>
        <div class="flex-1">
          <div class="flex justify-between space-x-2">
            <div>
          <span class="block text-sm font-medium text-gray-800">
            {{ product.name }}
          </span>
            </div>
            <div>
          <span class="block text-sm font-medium text-gray-800">
            {{ priceInEur(product.price * product.quantity) }}
          </span>
            </div>
          </div>
          <div class="flex">
            <div class="flex items-center">
              <span class="block text-xs text-gray-500">
                Količina
                <span class="text-gray-800 font-medium">
                  {{ product.quantity }}
                </span>
              </span>
<!--              <button-->
<!--                  class="grop flex items-center mx-0.5 py-px px-1 hover:bg-gray-100 transition-colors rounded"-->
<!--                  @click="$emit('openQuantityModal', product)"-->
<!--              >-->
<!--                <span class="block text-xs text-gray-800 font-medium">-->
<!--                  {{ product.quantity }}-->
<!--                </span>-->
<!--                <svg class="ml-0.5 stroke-gray-500 group-hover:stroke-gray-800 transition-colors" width="13"-->
<!--                     height="13" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M6 9L12 15L18 9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                </svg>-->
<!--              </button>-->
            </div>
            <div v-if="product.quantity>1" class="ml-auto">
              <span class="block text-xs text-gray-500">
                {{ priceInEur(product.price) }} svaki
              </span>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import {mapGetters} from "vuex";
import {priceInEur} from "@/helpers/price";

export default {
  data() {
    return {
      url: process.env.VUE_APP_TRGUJ_PAY + '/storage/items/'
    }
  },
  emits: ['openQuantityModal'],
  computed: {
    ...mapGetters("order", ["products"]),
  },
  methods: {
    priceInEur
  }
}
</script>

<style scoped>

</style>