<template>
  <AppModal ref="appModal"
            panel-classes="" card-classes="" width=""
            :show-dismiss="false"
            :panel-transition="{
              'enter': 'ease-out duration-300',
              'enter-from': 'opacity-0 -translate-y-full',
              'enter-to': 'opacity-100 translate-y-0',
              'leave': 'ease-in duration-200',
              'leave-from': 'opacity-100 translate-y-0',
              'leave-to': 'opacity-0 -translate-y-full'
            }"
            @close="$emit('close')"
  >
    <div class="bg-white p-4 pt-[3.75rem] overflow-hidden shadow-xl transform transition-all">
      <div class="max-w-sm mx-auto">
        <template v-if="products.length">
          <OrderItems
              @open-quantity-modal="$emit('openQuantityModal', $event)"
          ></OrderItems>
          <div class="flex justify-between mt-4 ml-14 pb-4">
          <span class="block text-sm font-medium text-gray-800">
            Ukupno
          </span>
            <span class="block font-semibold text-gray-800">
            {{ priceInEur(total) }}
          </span>
          </div>
        </template>

        <div v-else class="py-4">
          <p class="text-gray-700 text-center text-lg">
            Nema proizvoda
          </p>
        </div>
      </div>
    </div>
  </AppModal>
</template>

<script>
import {mapGetters} from "vuex";
import {priceInEur} from "@/helpers/price";

import OrderItems from "@/views/order/OrderItems";

export default {
  emits: ['close', 'openQuantityModal'],
  components: {
    OrderItems,
  },
  computed: {
    ...mapGetters("order", ["products", "total"]),
    panelTransition() {
      return {
        "enter": "ease-out duration-300",
        "enter-from": "opacity-0 -translate-y-full",
        "enter-to": "opacity-100 translate-y-0",
        "leave": "ease-in duration-200",
        "leave-from": "opacity-100 translate-y-0",
        "leave-to": "opacity-0 -translate-y-full"
      }
    }
  },
  methods: {
    priceInEur,
  },
}
</script>
