export const inputStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '6px',
    border: 'solid 1px #d1d5db',
    backgroundColor: '#ffffff',
    padding: '8px 12px',
    transition: 'none',
    fontFamily: 'inherit !important',
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: '1.25rem',
    color: '#111827',
    textAlign: 'left',
    letterSpacing: 'normal',
    outline: 'none',
    margin: '0px',
}

export const interactStyle = {
    padding: '7px 11px',
    borderWidth: '2px',
}

export const focusStyle = {
    padding: '8px 12px',
    borderWidth: '1px',
    color: '#111827',
    borderColor: '#8b5cf6',
}

export const errorStyle = {
    padding: '8px 12px',
    borderWidth: '1px',
    color: '#7f1d1d',
    borderColor: '#fca5a5',
}

export const interactErrorStyle = {
    borderColor: '#ef4444',
}
