export function priceInEur(price, scale = 2) {
    const total = price / 100;
    const fixedTotal = total.toFixed(scale);

    return `${fixedTotal}€`;
}

export function productsQtyLabel(qty) {
    return qty === 1 ? `${qty} proizvod` : `${qty} proizvoda`;
}
