<template>
  <TransitionIconZoom>
    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none overflow-hidden z-[1]">
      <!-- Animated cards -->
      <Transition enter-active-class="transition-[transform,opacity] duration-300 ease-in"
                  leave-active-class="z-[-1] transition-[transform,opacity] duration-300 ease-in"
                  enter-from-class="translate-x-full opacity-0"
                  leave-to-class="translate-x-full opacity-0"
      >
        <div v-show="!creditCardType" class="flex space-x-1">
          <!-- Direct cards -->
          <img v-for="directCreditCard in directCreditCards"
               :src="creditCards[directCreditCard].src"
               :alt="directCreditCard"
               :key="`direct-${directCreditCard}`"
          >

          <!-- Overflown cards -->
          <img v-show="activeOverflowCardIcon === 'visa'"
               :src="creditCards['visa'].src"
               :alt="activeOverflowCardIcon"
          >
          <img v-show="activeOverflowCardIcon === 'mastercard'"
               :src="creditCards['mastercard'].src"
               :alt="activeOverflowCardIcon"
          >
          <img v-show="activeOverflowCardIcon === 'maestro'"
               :src="creditCards['maestro'].src"
               :alt="activeOverflowCardIcon"
          >
        </div>
      </Transition>

      <!-- Matched card -->
      <TransitionIconZoom mode="out-in">
        <div v-if="creditCardType && creditCardIconExists"
             :key="`matched-${creditCardType}`"
        >
          <img :src="creditCards[creditCardType].src"
               :alt="creditCardType">
        </div>

        <span v-else v-text="creditCardType" class="text-xs text-gray-500"></span>
      </TransitionIconZoom>
    </div>
  </TransitionIconZoom>
</template>

<script>
import TransitionIconZoom from "@/components/transitions/TransitionIconZoom";

const CREDIT_CARDS = {
  visa: { src: require("@/assets/credit-cards/visa.svg") },
  mastercard: { src: require("@/assets/credit-cards/master-sm.svg") },
  maestro: { src: require("@/assets/credit-cards/maestro.svg") },
};

const CREDIT_CARDS_DIRECT_SHOW = 3;

export default {
  components: {
    TransitionIconZoom,
  },
  props: {
    creditCardType: {
      type: [String, null],
      required: true,
    },
  },
  data() {
    return {
      activeOverflowCardIcon: null,
    }
  },
  computed: {
    creditCards() {
      return CREDIT_CARDS;
    },
    directCreditCards() {
      return Object.keys(CREDIT_CARDS).slice(0, CREDIT_CARDS_DIRECT_SHOW);
    },
    overflownCreditCards() {
      return Object.keys(CREDIT_CARDS).slice(CREDIT_CARDS_DIRECT_SHOW);
    },
    creditCardIconExists() {
      return Object.keys(CREDIT_CARDS).includes(this.creditCardType);
    }
  },
  beforeMount() {
    this.animateOverflownCreditCards();
  },
  methods: {
    animateOverflownCreditCards() {
      let i = 0;
      this.activeOverflowCardIcon = this.overflownCreditCards[i];

      setInterval(() => {
        if (++i === this.overflownCreditCards.length) {
          i = 0;
        }

        this.activeOverflowCardIcon = this.overflownCreditCards[i];
      }, 2500);
    },
  },
}
</script>

<style scoped>

</style>