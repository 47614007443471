<template>
  <AppModal
      :dismissible="false"
      :transition-appear="false"
      bg-color="bg-red-500"
  >
    <div class="py-4">
      <p class="text-center text-xl text-white font-medium lg:text-2xl">
        {{ orderError }}
      </p>
    </div>
  </AppModal>
</template>

<script>
export default {
  props: {
    orderError: String
  }
}
</script>

<style scoped>

</style>