<template>
  <AppModal
      width="sm:max-w-lg"
      card-classes="w-full shadow-xl overflow-y-auto -mx-4 sm:mx-4 sm:rounded-lg"
      panel-classes="relative min-h-screen flex items-end justify-center transition-all sm:p-4 sm:items-center"
      :show-dismiss="false"
  >
    <template #default="{close}">
      <div class="divide-y">
        <div class="p-6 flex justify-between items-start">
          <h2 class="flex-1 text-gray-900 font-medium">
            Trguj.me
          </h2>
          <button @click="close"
                  class="ml-4 flex-shrink-0 text-gray-500"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <nav class="px-6 flex space-x-4">
          <span class="py-4 font-medium text-sm cursor-pointer"
                :class="activeTab === 'legal' ? 'text-violet-700' : 'text-gray-500 hover:text-gray-700'"
                @click="activeTab = 'legal'"
          >
            Uslovi
          </span>
            <span class="py-4 font-medium text-sm cursor-pointer"
                  :class="activeTab === 'contact' ? 'text-violet-700' : 'text-gray-500 hover:text-gray-700'"
                  @click="activeTab = 'contact'"
            >
            Kontakt
          </span>
        </nav>

        <div class="p-6 h-80 overflow-y-auto">
          <Transition
              enter-active-class="transition-[opacity,transform] duration-200"
              enter-from-class="translate-x-2 opacity-0"
              leave-active-class="transition-[opacity,transform] duration-300"
              leave-to-class="-translate-x-2 opacity-0"
              mode="out-in"
          >
            <div v-if="activeTab === 'legal'">
              <div class="prose prose-sm">
                <h2>Prava i obaveze</h2>

                <p>
                  <a href="https://trguj.me/terms-of-use" target="_blank">Uslovi korišćenja</a>

                </p>
                <p>
                  <a href="https://trguj.me/payment-terms" target="_blank">Uslovi plaćanja</a>

                </p>

                <p>
                  <a href="https://trguj.me/privacy-policy" target="_blank">Politika privatnosti</a>
                </p>
              </div>
            </div>
            <div v-else-if="activeTab === 'contact'">
              <div class="flex">
                <svg class="flex-shrink-0 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
                <div class="flex-1 ml-3 -mt-1">
                    <span class="font-medium text-sm text-gray-900">
                      Kontakt
                    </span>
                  <p class="text-sm text-gray-500 ">
                    Ako imate pitanja, posjetite našu
                    <a href="https://trguj.me" class="underline" target="_blank">
                      web stranicu
                    </a>.
                    Takođe nam možete poslati e-mail na
                    <a href="mailto:support@trguj.me" class="underline">
                      support@trguj.me
                    </a>
                    ili pozvati
                    <a href="tel:+38267066050" class="underline">+382 67 066 050</a>.
                  </p>
                </div>
              </div>
            </div>
          </Transition>
        </div>

        <div class="p-6">
          <p class="text-center text-gray-500 text-xs">
            Vaša prava u skladu sa našom politikom refundiranja/zamjene su dodatak svim pravima koja imate prema važećim zakonima o potrošačima.
          </p>
        </div>
      </div>
    </template>
  </AppModal>
</template>

<script>
export default {
  props: {
    tab: {
      type: String,
      default: "legal",
      validator(tab) {
        return ["legal", "returns", "contact"].includes(tab);
      },
    }
  },
  data() {
    return {
      activeTab: "legal",
    }
  },
  created() {
    if (this.tab) {
      this.activeTab = this.tab;
    }
  }
}
</script>

<style scoped>

</style>