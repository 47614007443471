<template>
  <button type="submit"
          class="relative inline-flex items-center justify-center mt-3 px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-violet-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500 transition-opacity"
          :class="[
              {'hover:opacity-50 cursor-wait' : busy},
              {'opacity-50 cursor-not-allowed' : disabled}
          ]"
          :disabled="disabled"
  >

    {{ busy ? busyText || text : text }}

    <TransitionIconZoom>
      <div v-if="busy" class="absolute right-0 top-1/2 -translate-y-1/2 -translate-x-4">
        <svg class="animate-spin h-5 w-5 text-white"
             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
        >
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
      <div v-else-if="!disabled" class="absolute right-0 top-1/2 -translate-y-1/2 -translate-x-4">
        <LockClosedIcon class="h-5 w-5 text-white" aria-hidden="true"/>
      </div>
    </TransitionIconZoom>
  </button>
</template>

<script>
import TransitionIconZoom from "@/components/transitions/TransitionIconZoom";
import {LockClosedIcon} from '@heroicons/vue/solid'

export default {
  components: {
    TransitionIconZoom,
    LockClosedIcon
  },
  props: {
    text: String,
    busyText: String,
    busy: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style scoped>

</style>