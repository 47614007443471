<template>
  <div>
    <div class="hidden lg:block lg:mb-4">
      <span class="block text-xl text-gray-800 font-medium">
        Plati karticom
      </span>
    </div>

    <AppLoader v-if="!paymentInitialized"></AppLoader>
    <form v-show="paymentInitialized"
          @submit.prevent="submit"
    >
      <div class="-m-4">
        <div class="p-4">
          <AppLabel for="email" :required="validation.email.required">
            Email
          </AppLabel>
          <AppInput
              v-model="formData.email"
              type="email"
              id="email"
              class="mt-1"
              autocomplete="email"
              icon="trailing"
              :error="validation.email.error"
              :hasError="validation.email.hasError"
              :aria-invalid="validation.email.hasError"
              @change="dirtyField('email')"
              @blur="handleInputBlur('email')"
              @input="resetDirty('email')"
          >
            <template v-if="validation.email.required" #icon="{classes}">
              <ExclamationCircleIcon :class="classes"/>
            </template>
          </AppInput>
        </div>

        <div class="p-4">
          <AppLabel for="card_number"
                    :required="validation.number.required"
                    @click="payment.focusNumber()"
          >
            Broj kartice
          </AppLabel>
          <DefaultField :error="validation.number.error">
            <div class="relative">
              <div id="card_number"
                   class="mt-1 h-[2.625rem] sm:h-[2.375rem] shadow-sm rounded-md"
              ></div>
              <TransitionIconZoom>
                <div v-if="validation.number.required"
                     class="absolute inset-y-0 flex items-center pointer-events-none right-0 pr-3 text-red-500"
                >
                  <ExclamationCircleIcon class="h-5 w-5"/>
                </div>
              </TransitionIconZoom>
              <CreditCardsAnimation
                  v-show="!validation.number.required && !validation.number.hasError"
                  :creditCardType="creditCardType"
              ></CreditCardsAnimation>
            </div>
          </DefaultField>
        </div>

        <div class="sm:flex">
          <div class="p-4 sm:pr-2 sm:w-1/2">
            <AppLabel for="cvv_number"
                      :required="validation.cvv.required"
                      @click="payment.focusCvv()"
            >
              CVV broj
            </AppLabel>
            <DefaultField :error="validation.cvv.error">
              <div class="relative">
                <div id="cvv_number"
                     class="mt-1 h-[2.625rem] sm:h-[2.375rem] shadow-sm rounded-md"
                ></div>
                <TransitionIconZoom mode="out-in">
                  <div v-if="validation.cvv.required"
                       class="absolute inset-y-0 flex items-center pointer-events-none right-0 pr-3 text-red-500"
                  >
                    <ExclamationCircleIcon class="h-5 w-5"/>
                  </div>
                </TransitionIconZoom>
              </div>
            </DefaultField>
          </div>

          <div class="p-4 sm:pl-2 sm:w-1/2">
            <AppLabel for="card-expiration-date" :required="validation.card_expiration_date.required">
              Datum isteka
            </AppLabel>
            <AppInput
                v-model="formData.card_expiration_date"
                mask="##/##"
                id="card-expiration-date"
                class="mt-1"
                autocomplete="cc-exp"
                placeholder="MM / YY"
                icon="trailing"
                :error="validation.card_expiration_date.error"
                :hasError="validation.card_expiration_date.hasError"
                :aria-invalid="validation.card_expiration_date.hasError"
                @change="dirtyField('card_expiration_date')"
                @blur="handleInputBlur('card_expiration_date')"
                @update:modelValue="onExpDateUpdateModalValue"
            >
              <template v-if="validation.card_expiration_date.required" #icon="{classes}">
                <ExclamationCircleIcon :class="classes"/>
              </template>
            </AppInput>
          </div>
        </div>

        <div class="p-4">
          <AppLabel for="card-name" :required="validation.card_holder.required">
            Ime na kartici
          </AppLabel>
          <AppInput
              v-model="formData.card_holder"
              id="card-name"
              class="mt-1"
              autocomplete="cc-name"
              icon="trailing"
              :error="validation.card_holder.error"
              :hasError="validation.card_holder.hasError"
              :aria-invalid="validation.card_holder.hasError"
              @change="dirtyField('card_holder')"
              @blur="handleInputBlur('card_holder')"
              @input="resetDirty('card_holder')"
          >
            <template v-if="validation.card_holder.required" #icon="{classes}">
              <ExclamationCircleIcon :class="classes"/>
            </template>
          </AppInput>
        </div>

        <div class="p-4">
            <AppCheckbox
                @check="checkTheBox()"
                :value="formData.accepted" >
              <span class="ml-2">Prihvatam <a href="https://trguj.me/payment-terms" target="_blank" class="underline">Uslove plaćanja</a></span>
            </AppCheckbox>

        </div>
      </div>

      <div class="flex flex-col mt-4 mb-[20px]">
        <PayButton text="Plati"
                   :busy="paying"
                   busy-text="Plaćanje..."
                   :disabled="!validForm || !formData.accepted"
        ></PayButton>
      </div>
    </form>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import {required, email, helpers} from '@vuelidate/validators'
import {useToast} from "vue-toastification";

import {inputStyle, focusStyle, errorStyle, interactStyle, interactErrorStyle} from "@/payment-style";
import paymentJsErrorCodes from "@/helpers/paymentJsErrorCodes";

import CreditCardsAnimation from "@/views/order/partials/CreditCardsAnimation";
import TransitionIconZoom from "@/components/transitions/TransitionIconZoom";
import DefaultField from "@/components/DefaultField";
import PayButton from "@/views/order/partials/PayButton";

import {ExclamationCircleIcon} from '@heroicons/vue/outline'
import AppCheckbox from "@/components/AppCheckbox.vue";

const MIN_CARD_YEAR = new Date().getFullYear();
const MIN_CARD_MONTH = new Date().getMonth() + 1;

export default {
  components: {
    AppCheckbox,
    ExclamationCircleIcon,
    CreditCardsAnimation,
    TransitionIconZoom,
    DefaultField,
    PayButton
  },
  setup() {
    const toast = useToast();

    return {
      v$: useVuelidate(),
      toast
    }
  },
  data() {
    return {
      formData: {
        email: '',
        card_expiration_date: '',
        card_holder: '',
        accepted: false
      },

      // server validation
      serverValidation: {
        card_expiration_date: [],
        card_holder: [],
        number: [],
        cvv: [],
      },

      numberEmpty: true,
      cvvEmpty: true,

      payment: new window.PaymentJs(),
      paymentJsErrorCodes: paymentJsErrorCodes,
      creditCardType: null,

      paymentInitialized: false,
      paying: false,
    }
  },
  computed: {
    validation() {
      return {
        email: this.validateField('email'),
        card_holder: this.validateField('card_holder'),
        card_expiration_date: this.validateField('card_expiration_date'),
        number: this.validateField('number'),
        cvv: this.validateField('cvv'),
        accepted: this.validateField('accepted')
      }
    },
    validForm() {
      return !this.v$.formData.$invalid;
    },
    cardMonth() {
      const cardMM = this.formData.card_expiration_date.split('/')[0];
      if (cardMM && cardMM.length === 2) {
        return +cardMM;
      }
      return null;
    },
    cardYear() {
      const cardYY = this.formData.card_expiration_date.split('/')[1];
      if (cardYY && cardYY.length === 2) {
        return cardYY < 90 ? +`20${cardYY}` : +`19${cardYY}`;
      }
      return null;
    },
  },
  beforeMount() {
    this.$watch("validation.number.hasError", (numberHasError) => {
      if (numberHasError) this.payment.setNumberStyle(errorStyle);
    });

    this.$watch("validation.cvv.hasError", (cvvHasError) => {
      if (cvvHasError) this.payment.setCvvStyle(errorStyle);
    });
  },
  mounted() {
    this.initializeAllSecure();
  },
  methods: {
    checkTheBox() {
      this.formData.accepted = !this.formData.accepted;
    },
    initializeAllSecure() {
      this.payment.init(
          process.env.VUE_APP_ALL_SECURE_PUBLIC_KEY,
          'card_number',
          'cvv_number',
          payment => {
            // Placeholders
            payment.setNumberPlaceholder("1234 1234 1234 1234");
            payment.setCvvPlaceholder("CVV");

            // Initial style
            payment.setNumberStyle(inputStyle);
            payment.setCvvStyle(inputStyle);

            const onCvvBlur = ({validCvv, cvvLength}) => {
              onFieldBlur({
                formDataField: "cvv",
                fieldStyleMethod: "setCvvStyle",
                validField: validCvv,
                fieldLength: cvvLength,
              });
            };

            const onCvvInput = () => {
              onFieldInput({
                formDataField: "cvv",
                fieldStyleMethod: "setCvvStyle"
              });
            };

            // FIELD HANDLERS
            const onFieldInput = ({formDataField, fieldStyleMethod}) => {
              this.resetDirty(formDataField);
              this.serverValidation[formDataField] = [];

              payment[fieldStyleMethod]({...inputStyle, ...focusStyle, ...interactStyle});
            };

            const onFieldFocus = ({formDataField, fieldStyleMethod}) => {
              const activeStyle = this.validation[formDataField].hasError
                  ? {...errorStyle, ...interactErrorStyle}
                  : focusStyle;

              payment[fieldStyleMethod]({...inputStyle, ...activeStyle, ...interactStyle});
            };

            const onFieldBlur = ({formDataField, fieldStyleMethod, validField, fieldLength}) => {
              const isEmptyField = fieldLength === 0;

              // Frontend invalid field error
              if (!validField && !this.serverValidation[formDataField].length) {
                const errors = {
                  [formDataField]: [this.paymentJsErrorCodes.getMessage(formDataField, 'errors.invalid')],
                };

                Object.assign(this.serverValidation, errors);
              }

              // To validate required
              if (formDataField === "number") this.numberEmpty = isEmptyField;
              if (formDataField === "cvv") this.cvvEmpty = isEmptyField;

              this.dirtyField(formDataField);

              payment[fieldStyleMethod](this.validation[formDataField].hasError ? errorStyle : inputStyle);
            };

            // EVENTS
            // Card Holder
            payment.numberOn('input', ({cardType, validCvv, cvvLength}) => {
              this.creditCardType = cardType;

              onFieldInput({
                formDataField: "number",
                fieldStyleMethod: "setNumberStyle"
              });

              if (validCvv && this.validation['cvv'].hasError) {
                onCvvInput();
                onCvvBlur({validCvv, cvvLength});
              }
            });

            payment.numberOn('focus', () => {
              onFieldFocus({
                formDataField: "number",
                fieldStyleMethod: "setNumberStyle"
              });
            });

            payment.numberOn('blur', ({validNumber, numberLength}) => {
              onFieldBlur({
                formDataField: "number",
                fieldStyleMethod: "setNumberStyle",
                validField: validNumber,
                fieldLength: numberLength,
              });
            });

            // CVV
            payment.cvvOn('input', onCvvInput);

            payment.cvvOn('focus', () => {
              onFieldFocus({
                formDataField: "cvv",
                fieldStyleMethod: "setCvvStyle"
              });
            });

            payment.cvvOn('blur', ({validCvv, cvvLength}) => {
              onCvvBlur({validCvv, cvvLength});
            });

            this.paymentInitialized = true;
          }
      );
    },
    async submit() {
      if (!this.validForm) return;

      this.paying = true;

      this.resetServerValidation();

      // check if everything is valid
      if (!await this.v$.$validate()) {
        const invalidInputs = document.querySelectorAll("[aria-invalid='true']");

        // Focus first input with error
        if (invalidInputs[0].id === "email") invalidInputs[0]?.focus();
        else if (this.validation.number.hasError) this.payment.focusNumber();
        else if (this.validation.cvv.hasError) this.payment.focusCvv();
        else {
          invalidInputs[0]?.focus();
        }

        this.paying = false;

        return;
      }

      const cardAdditional = {
        card_holder: this.formData.card_holder,
        month: this.cardMonth,
        year: this.cardYear
      };

      this.payment.tokenize(
          cardAdditional,
          (token) => {
            // send to backend
            window.axios.post(`/order/${this.$route.params.order}`, {
              email: this.formData.email,
              cardholder: this.formData.card_holder,
              transactionToken: token
            })
                .then(
                    ({data}) => {
                      const {redirectUrl} = data.data;
                      if (redirectUrl) window.location.href = redirectUrl;
                    },
                    () => this.toast.error("Došlo je do greške")
                )
                .finally(() => this.paying = false);
          },
          errors => {
            errors.reduce(
                (memo, error) => {

                  const translatedMsg =
                      this.paymentJsErrorCodes.getMessage(error.attribute, error.key)
                      || error.message;

                  // Month and year errors within same attribute
                  if (["month", "year"].includes(error.attribute)) {
                    memo['card_expiration_date'] =
                        [...memo['card_expiration_date'], translatedMsg];
                  } else {
                    memo[error.attribute] = [...memo[error.attribute], translatedMsg];
                  }

                  return memo;
                }, this.serverValidation
            );

            errors.forEach(error => {
              if (error.attribute === "number" && error.key === "errors.blank") {
                this.numberEmpty = true
              }

              if (error.attribute === "cvv" && error.key === "errors.blank") {
                this.cvvEmpty = true;
              }
            });

            this.paying = false;
          }
      );
    },
    resetServerValidation() {
      const keys = Object.keys(this.serverValidation);

      keys.forEach(key => {
        this.serverValidation[key] = [];
      })
    },
    handleInputBlur(field) {
      // All secure fields doesn't have @change handler, so for them showing errors on blur
      if (!this.v$.formData[field].$dirty) return;

      this.dirtyField(field);
    },
    dirtyField(field) {
      this.v$.formData[field].$touch();
    },
    resetDirty(field) {
      this.v$.formData[field].$reset();
    },
    validateField(field) {
      let requiredInvalid = false;
      let hasError = this.v$.formData[field].$error;
      let error = null;

      if (this.v$.formData[field].required) {
        requiredInvalid = this.v$.formData[field].required.$invalid;
      }

      if (hasError && !requiredInvalid) {
        if (this.v$.formData[field].$errors[0].$validator === "serverValidation") {
          error = this.serverValidation[field][0];
        } else {
          error = this.v$.formData[field].$errors[0].$message;
        }
      }

      return {
        required: requiredInvalid && hasError,
        hasError,
        error,
      }
    },
    onExpDateUpdateModalValue() {
      this.resetDirty("card_expiration_date");

      const cardMM = this.formData.card_expiration_date.split("/")[0];

      if (cardMM) {
        const [firstM, secondM] = cardMM.split("");

        if (Number(firstM) > 1) {
          this.formData.card_expiration_date = `0${this.formData.card_expiration_date}`;
        }

        if (secondM) {
          if (firstM === "0" && secondM === "0") {
            this.formData.card_expiration_date = this.formData.card_expiration_date.slice(0, 1);
          }

          if (firstM === '1' && !['0', '1', '2'].includes(secondM)) {
            this.formData.card_expiration_date = this.formData.card_expiration_date.slice(0, 1);
          }
        }
      }
    },
  },
  validations() {
    return {
      formData: {
        email: {
          email: helpers.withMessage("Email je nedovršen", email),
          required
        },
        accepted: {
            required,
        },
        card_expiration_date: {
          required,
          isComplete: {
            $message: "Datum isteka je nedovršen",
            $validator: () => {
              return this.formData.card_expiration_date.length === 5;
            }
          },
          isValidFormat: {
            $message: "Neispravan format",
            $validator: () => {
              return this.formData.card_expiration_date.includes("/")
                  && this.formData.card_expiration_date.charAt(2) === "/";
            }
          },
          isValidYear: {
            $message: "Godina isteka je u prošlosti",
            $validator: () => {
              return this.cardYear >= MIN_CARD_YEAR;
            }
          },
          monthInPast: {
            $message: "Mjesec isteka je u prošlosti",
            $validator: () => {
              const validYear = this.v$.formData.card_expiration_date.isValidYear.$invalid;

              if (validYear && (this.cardYear === MIN_CARD_YEAR)) {
                return this.cardMonth < MIN_CARD_MONTH;
              }

              return true;
            }
          },
          isValidMonth: {
            $message: "Mjesec isteka je neispravan",
            $validator: () => {
              return this.cardMonth >= 1 && this.cardMonth <= 12;
            }
          },
          serverValidation: () => {
            return !this.serverValidation['card_expiration_date'].length;
          }
        },
        card_holder: {
          required,
          serverValidation: () => {
            return !this.serverValidation['card_holder'].length;
          }
        },
        number: {
          required: {
            $message: this.paymentJsErrorCodes.getMessage("number", "errors.invalid"),
            $validator: () => {
              return !this.numberEmpty;
            }
          },
          serverValidation: () => {
            return !this.serverValidation['number'].length;
          }
        },
        cvv: {
          required: {
            $message: this.paymentJsErrorCodes.getMessage("cvv", "errors.invalid"),
            $validator: () => {
              return !this.cvvEmpty;
            }
          },
          serverValidation: () => {
            return !this.serverValidation['cvv'].length;
          }
        },
      }
    }
  },
}
</script>
<style scoped>

</style>